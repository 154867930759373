import { Srdata } from './../model/route.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { activityUriConfig } from '@app/trips/constants/trip-config-url';
import {
  Service,
  TripServices,
} from '@app/trips/trips-tab/model/trip-service.model';
import { CommonService } from '@app/shared/services/common.service';
import { TripListDataModel } from '../model/trip-list-details.model';
import { AlertController } from '@ionic/angular';
import { TimerService } from '@app/shared/services/timer.service';
enum trip_status {
  Inprogress = 'In progress',
  confirmed = 'confirmed',
  Upcoming = 'Upcoming',
  Completed = 'Completed',
}
@Injectable({
  providedIn: 'root',
})
export class TripService {
  public apiType = ApiTypes;
  public tripServices: any;
  public actionItemsList: BehaviorSubject<any> = new BehaviorSubject([]);
  public servicesList: BehaviorSubject<any> = new BehaviorSubject([]);
  public selectFlightPlan: boolean = false;
  public quoteData: any;
  public tripData = new Subject<TripListDataModel>();
  public reloadActivity = new Subject<boolean>();
  public charterCancelChange: Subject<boolean> = new Subject();
  constructor(
    private serverConnectService: ServerConnectService,
    private commonService: CommonService,
    private authSessionService: AuthSessionService,
    private timerService: TimerService,
  ) { }

  // for operator trip fetch
  public loadData(status, name?) {
    if (this.commonService.roleId != '3') {
      this.quoteData = null;
      if (this.quoteData) {
        return Promise.resolve(this.quoteData);
      }
      return new Promise((resolve, reject) => {
        const QuoteAllData = 'enct=1&status=' + status + '';
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getQuoteAll,
            QuoteAllData,
            false,
            false,
            false
          )
          .then((data: any) => {
            this.quoteData = data.GetQuoteAll;
            if (Array.isArray(this.quoteData)) {
              resolve(this.quoteData);
          } else if (typeof this.quoteData == 'object' && this.quoteData !== null) {
              const sequentialArray: any[] = [];
              let expectedIndex = 0;
              for (const key in this.quoteData) {
                  if (this.quoteData.hasOwnProperty(key)) {
                      const element = this.quoteData[key];
                      while (expectedIndex < +key) {
                          sequentialArray.push({});
                          expectedIndex++;
                      }
                      sequentialArray.push(element);
                      expectedIndex++;
                  }
              }
              while (expectedIndex < sequentialArray.length) {
                  sequentialArray.push({});
                  expectedIndex++;
              }
              resolve(sequentialArray);
          } else {
            resolve('Error');
          }
          })
          .catch((err) => {
            resolve('Error');
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.loadData(status, name);
              } else {
                reject();
              }
            });
          });
      });
    }
  }
  // call list without timer for vendor trip fetch
  public getQuoteListRecords(status, pagename?) {
    this.quoteData = null;
    if (this.quoteData) {
      return Promise.resolve(this.quoteData);
    }
    return new Promise((resolve, reject) => {
      const VendorTripsData = 'enct=1&status=' + status + '';
      if (pagename == 'Trip') {
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getVendorTripsAll,
            VendorTripsData,
            false,
            false,
            false
          )
          .then((data: any) => {
            this.quoteData = data.GetVendorTripsAll;
            resolve(this.quoteData);
          })
          .catch((err) => {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.getQuoteListRecords(status, pagename);
              } else {
                reject();
              }
            });
          });
      } else {
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getVendorTrips,
            VendorTripsData,
            false,
            false,
            false
          )
          .then((tripData: any) => {
            this.quoteData = tripData.GetVendorTrips;
            resolve(this.quoteData);
          })
          .catch((err) => {
            resolve('Error');
          });
      }
    });
  }
  public loadDataDoinfineload(status: string, templastsrid: string, role: any) {
    if (role == 2) {
      return new Promise((resolve) => {
        const tripsData =
          'enct=1&status=' + status + '&lastminsrid=' + templastsrid;
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getQuoteAll,
            tripsData,
            false,
            false,
            false
          )
          .then((quoteData: any) => {
            this.quoteData = quoteData.GetQuoteAll;
            resolve(this.quoteData);
          })
          .catch((error) => {
            resolve('Error');
          });
      });
    }
    if (role == 3) {
      return new Promise((resolve) => {
        const tripsData =
          'enct=1&status=' + status + '&lastminsrid=' + templastsrid;
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getVendorTrips,
            tripsData,
            false,
            false,
            false
          )
          .then((TripsData: any) => {
            const quotedata = TripsData.GetVendorTrips;
            resolve(quotedata);
          })
          .catch((error) => {
            resolve('Error');
          });
      });
    }
  }
  public cancelTripByClient(srid, status) {
    return new Promise((resolve) => {
      const cancelTripData = 'enct=1&srid=' + srid + '&type=' + status;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          activityUriConfig.cancelTrip,
          cancelTripData,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }
  public storeFilteredActionItemsList(actionItems) {
    this.actionItemsList.next(actionItems);
  }
  public passFilteredServices(servicesData) {
    servicesData = servicesData
      .filter((service) => {
        return (
          service.servicename != 'Select Fuel On' &&
          service.legStatus != 'Closed'
        );
      })
      .map((item) => {
        return {
          category: item.category,
          sector: item.sector,
          legId: Number(item.legId),
          order: item.order,
          legStatus: item.legStatus,
          checked: item.checked != undefined ? item.checked : false,
          statusToggle:
            item.statusToggle != undefined ? item.statusToggle : false,
          sectorToggle:
            item.sectorToggle != undefined ? item.sectorToggle : false,
          serviceToggle:
            item.serviceToggle != undefined ? item.serviceToggle : false,
        };
      });
    this.servicesList.next(servicesData);
  }
  public fetchTripService(tripId: number) {
    return new Promise((resolve, reject) => {
      let databody = 'id=' + tripId;
      if (this.commonService.roleId == 2) {
        // client
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getClientTripService,
            databody,
            false,
            false,
            false
          )
          .then((tripData: any) => {
            resolve(
              this.createClientServiceArr(tripData.GetTripServiceDetails)
            );
          })
          .catch((error) => {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.fetchTripService(tripId);
              } else {
                reject();
              }
            });
          });
      } else if (this.commonService.roleId == 3) {
        // vendor
        databody = 'tripID=' + tripId;
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getVendorTripServiceDetails,
            databody,
            false,
            false,
            false
          )
          .then((tripData: any) => {
            resolve(
              this.createVendorServiceArr(tripData.GetVendorTripsService)
            );
          })
          .catch((error) => {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.fetchTripService(tripId);
              } else {
                reject();
              }
            });
          });
      }
    });
  }
  public getFlightPlanSelectedData() {
    for (const service in this.tripServices) {
      if (service == 'crew briefing') {
        this.setSelectedFlightPlan(service, this.tripServices[service]);
      }
    }
  }
  public setSelectedFlightPlan(service, leg) {
    const legData = this.commonService.roleId == 3 ? leg[0] : leg;
    if (
      service == 'crew briefing' &&
      legData.UploadedArray &&
      legData.UploadedArray.length > 0
    ) {
      legData.UploadedArray.filter((element: any) => {
            if (element.Selected== '1') {
              return this.selectFlightPlan = true
            }
        });
    }
  }

  public createVendorServiceArr(tripDetails: any): Service {
    const tripServices = new Service();
    this.tripServices = [];
    for (const typeOfService in tripDetails.quotes) {
      if (typeOfService) {
        for (const legid in tripDetails.quotes[typeOfService]) {
          if (legid) {
            for (const serviceName in tripDetails.quotes[typeOfService][
              legid
            ]) {
              if (serviceName) {
                this.tripServices = tripDetails.quotes[typeOfService][legid];
                tripDetails.quotes[typeOfService][legid][serviceName].forEach(
                  (leg, index) => {
                    this.getFlightPlanSelectedData();
                    if (
                      serviceName == 'permits' ||
                      serviceName == 'crew briefing'
                    ) {
                      this.makeServiceLoopArr(
                        tripServices,
                        leg.SRSecID,
                        leg.Sector,
                        'sector',
                        legid
                      );
                      tripServices.sector.push({
                        qoutes:tripDetails.quotes,
                        FlowID: leg.FlowID,
                        SRID: tripDetails.SRID,
                        legIndex: legid,
                        SRStnID: leg.SRStnID,
                        legId: leg.SRSecID,
                        serviceDetails:
                          tripDetails.Status == 'Processing'
                            ? leg.ratebreakarray
                            : leg.UploadedArray
                              ? leg.UploadedArray
                              : leg.subcharges,
                        subservicedetails:
                          serviceName == 'crew briefing' &&
                            leg.Status == 'confirmed'
                            ? leg.UploadedArray
                            : leg.subcharges,
                        flightPlanningUploaded:
                          (serviceName == 'crew briefing' ||
                            serviceName == 'permits') &&
                            leg.Status == 'confirmed' &&
                            tripDetails.Status != 'Processing'
                            ? leg.FlightPlanUploaded
                            : false,
                        taxes: leg.taxes,
                        price: leg.price ? leg.price : leg.Price,
                        Status: leg.Status ? leg.Status : '',
                        status: this.statusOfVendorService(
                          serviceName,
                          leg,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage,
                          tripDetails.Status
                        ),
                        sector: leg.Sector,
                        legStatus: tripDetails.srdata.Legs.filter((item) => {
                          return item.legid.indexOf(String(leg.SRSecID)) > -1;
                        })[0].Legstage,
                        tripStatus: tripDetails.Status,
                        location: leg.Sector,
                        category: this.get_catagory_name(serviceName),
                        vendorid: leg.VendorID,
                        chatFirebaseId: tripDetails.clientDetails.firebase_uid,
                        name: tripDetails.clientDetails.ClientName,
                        servicename:
                          serviceName == 'permits'
                            ? 'Overflight Permits'
                            : 'Flight Planning',
                        selectFlightPlan:
                          leg.FlightPlanSelected != undefined
                            ? leg.FlightPlanSelected
                            : this.selectFlightPlan,
                        serviceType: 'sector',
                        requestType: 'Operator',
                        logo: leg.logo,
                        departSecond: tripDetails.srdata.Legs.filter((item) => {
                          return item.legid.indexOf(String(leg.SRSecID)) > -1;
                        })[0].departDate,
                        createSecond: tripDetails.srdata.DateCreated,
                        timerCount: this.getServiceDeliveryDateTime(
                          tripDetails.srdata.DateCreated,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].departDate,
                          leg.Sector,
                          leg.LocationName
                            ? leg.LocationName
                            : leg.Sector,
                            tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].BLKTIME,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage,
                          tripDetails.Status,
                          "timer",
                          "sector",
                        ),
                        vendorID: tripDetails.clientDetails.ClientID,
                        ClientOperationContactNo:
                          tripDetails.clientDetails.ClientOperationContactNo,
                        callingCodeOpscontact:
                          tripDetails.clientDetails.callingCodeOpscontact,
                        NavigationCost:
                          serviceName == 'permits' ? leg.NavigationCost : 0,
                          AircraftType: tripDetails.srdata.Regd,
                      });
                    } else if (serviceName == 'fuel') {
                      this.makeServiceLoopArr(
                        tripServices,
                        leg.SRSecID,
                        leg.LocationName,
                        'station',
                        legid
                      );
                      tripServices.station.push({
                        FlowID: leg.FlowID,
                        SRID: tripDetails.SRID,
                        legIndex: legid,
                        legId: leg.SRSecID,
                        SRStnID: leg.SRStnID,

                        serviceDetails:
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage == 'Processing'
                            ? leg.ratebreakarray
                            : leg.fuelsubcharges,
                        subservicedetails: leg.fuelDetails,
                        taxes: leg.taxes,
                        price: leg.price ? leg.price : leg.Price,
                        Status: leg.Status ? leg.Status : '',
                        status: this.statusOfVendorService(
                          serviceName,
                          leg,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage,
                          tripDetails.Status
                        ),
                        sector: leg.Sector,
                        legStatus: tripDetails.srdata.Legs.filter((item) => {
                          return item.legid.indexOf(String(leg.SRSecID)) > -1;
                        })[0].Legstage,
                        tripStatus: tripDetails.Status,
                        location: leg.LocationName,
                        vendorid: leg.VendorID,
                        chatFirebaseId: tripDetails.clientDetails.firebase_uid,
                        name: tripDetails.clientDetails.ClientName,
                        category: this.get_catagory_name(serviceName),
                        servicename: 'Fuel',
                        serviceType: 'station',
                        requestType: 'Operator',
                        logo: leg.logo,
                        departSecond: tripDetails.srdata.Legs.filter((item) => {
                          return item.legid.indexOf(String(leg.SRSecID)) > -1;
                        })[0].departDate,
                        createSecond: tripDetails.srdata.DateCreated,
                        timerCount: this.getServiceDeliveryDateTime(
                          tripDetails.srdata.DateCreated,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].departDate,
                          leg.Sector,
                          leg.LocationName
                            ? leg.LocationName
                            : leg.Sector,
                            tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].BLKTIME,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage,
                          tripDetails.Status,
                          "timer",
                          "sector",
                        ),
                        fuelDetails: leg.fuelDetails,
                        fuelDetailsInprogress: leg.fuelDetailsInprogress,
                        vendorID: tripDetails.clientDetails.ClientID,
                        ClientOperationContactNo:
                          tripDetails.clientDetails.ClientOperationContactNo,
                        callingCodeOpscontact:
                          tripDetails.clientDetails.callingCodeOpscontact,
                        fuelIntoplanAgentDetails: leg.fuelIntoplanAgentDetails,
                        DisableArray: leg.DisableArray,
                      });
                    } else {
                      let onTimeOfService: string;
                      let arrivalOrDepature: string;
                      if (leg.Sector.substring(0, 4) == leg.LocationName) {
                        onTimeOfService = 'Dep ';
                        arrivalOrDepature = onTimeOfService;
                      } else {
                        onTimeOfService = 'Arr ';
                        arrivalOrDepature = onTimeOfService;
                      }
                      this.makeServiceLoopArr(
                        tripServices,
                        leg.SRSecID,
                        leg.LocationName,
                        'station',
                        legid
                      );
                      tripServices.station.push({
                        SRID: tripDetails.SRID,
                        legIndex: legid,
                        legId: leg.SRSecID,
                        SRStnID: leg.SRStnID,
                        FlowID: leg.FlowID,
                        serviceDetails:
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage == 'Processing'
                            ? leg.ratebreakarray
                            : leg.subcharges,
                        taxes: leg.taxes,
                        price: leg.Price ? leg.Price : leg.price,
                        Status: leg.Status ? leg.Status : '',
                        status: this.statusOfVendorService(
                          serviceName,
                          leg,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage,
                          tripDetails.Status
                        ),
                        sector: leg.Sector,
                        legStatus: tripDetails.srdata.Legs.filter((item) => {
                          return item.legid.indexOf(String(leg.SRSecID)) > -1;
                        })[0].Legstage,
                        tripStatus: tripDetails.Status,
                        category: this.get_catagory_name(serviceName),
                        location: leg.LocationName,
                        vendorid: leg.VendorID,
                        chatFirebaseId: tripDetails.clientDetails.firebase_uid,
                        name: tripDetails.clientDetails.ClientName,
                        onTimeOfService,
                        servicename:
                          serviceName == 'handling'
                            ? 'Handling'
                            : 'Apt Fees & Permits',
                        serviceType: 'station',
                        requestType: 'Operator',
                        logo: leg.logo,
                        departSecond: tripDetails.srdata.Legs.filter((item) => {
                          return item.legid.indexOf(String(leg.SRSecID)) > -1;
                        })[0].departDate,
                        createSecond: tripDetails.srdata.DateCreated,
                        timerCount: this.getServiceDeliveryDateTime(
                          tripDetails.srdata.DateCreated,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].departDate,
                          leg.Sector,
                          leg.LocationName
                            ? leg.LocationName
                            : leg.Sector,
                            tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].BLKTIME,
                          tripDetails.srdata.Legs.filter((item) => {
                            return item.legid.indexOf(String(leg.SRSecID)) > -1;
                          })[0].Legstage,
                          tripDetails.Status,
                          "timer",
                          "sector",
                        ),
                        subservicedetails: leg.subservicedetails,
                        airlandingandslotpermits:
                          serviceName != 'handling'
                            ? leg.airlandingandslotpermits
                            : [],
                        vendorID: tripDetails.clientDetails.ClientID,
                        ClientOperationContactNo:
                          tripDetails.clientDetails.ClientOperationContactNo,
                        callingCodeOpscontact:
                          tripDetails.clientDetails.callingCodeOpscontact,
                        fuelDetails:
                          leg.fuelDetails == undefined
                            ? (leg.fuelDetails = [])
                            : leg.fuelDetails,
                        handlerdetails: leg.handlerDetails,
                        crewDetails:
                          tripDetails.assgnedcrewdetails &&
                            tripDetails.assgnedcrewdetails[index] &&
                            tripDetails.assgnedcrewdetails[index][leg.SRSecID] &&
                            tripDetails.assgnedcrewdetails[index][leg.SRSecID]
                              .list.length > 0
                            ? tripDetails.assgnedcrewdetails[index][
                              leg.SRSecID
                            ].list.map((crewdata: any) => {
                              return {
                                crewid: crewdata.ClientCrewID,
                                crewname: crewdata.ClientCrewName,
                              };
                            })
                            : [],
                        ArrDeptStatus:
                          leg.ArrDeptStatus == 'inprogress' ? true : false,
                        previousLegId: this.prevLegandSector(
                          Number(leg.SRSecID),
                          typeOfService,
                          serviceName,
                          tripDetails,
                          leg.LocationName,
                          leg.Sector,
                          'led_id'
                        ),
                        previousSector: this.prevLegandSector(
                          Number(leg.SRSecID),
                          typeOfService,
                          serviceName,
                          tripDetails,
                          leg.LocationName,
                          leg.Sector,
                          'sector'
                        ),
                        previousLegStatus: this.prevLegandSector(
                          Number(leg.SRSecID),
                          typeOfService,
                          serviceName,
                          tripDetails,
                          leg.LocationName,
                          leg.Sector,
                          'status'
                        ),
                        DepartureAttachments : leg.DepartureAttachments,
                        ArrivalAttachments : leg.ArrivalAttachments,
                        arrivalOrDepature,
                      });
                    }
                  }
                );
              }
            }
          }
        }
      }
    }
    return tripServices;
  }
  public prevLegandSector(
    legid: number,
    typeOfService: string,
    serviceName: string,
    tripDetails: any,
    station: string,
    sector: string,
    type: string
  ): any {
    const index = tripDetails.srdata.Legs.map((e) => {
      return e.legid;
    }).indexOf(String(legid));
    if (
      index > 0 &&
      station == sector.substring(0, 4) &&
      tripDetails.srdata.Legs.length > 1
    ) {
      if (type == 'led_id') {
        return legid - 1;
      } else if (type == 'sector') {
        return tripDetails.quotes[typeOfService][index - 1] &&
          tripDetails.quotes[typeOfService][index - 1][serviceName]
          ? tripDetails.quotes[typeOfService][index - 1][serviceName][0].Sector
          : false;
      } else if (type == 'status') {
        const legStage = tripDetails.srdata.Legs.filter((item) => {
          return item.legid == legid - 1;
        });
        if (
          legStage.length &&
          (legStage[0].Legstage == 'Inprogressstarting' ||
            legStage[0].Legstage == 'Inprogressfinishing' ||
            legStage[0].Legstage == 'Inprogressfinished')
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return null;
    }
  }
  public createClientServiceArr(tripDetails: any): Service {
    const tripServices = new Service();
    this.tripServices = [];
    for (const typeOfservice in tripDetails.quotes) {
      if (typeOfservice) {
        for (const legid in tripDetails.quotes[typeOfservice]) {
          if (legid) {
            this.tripServices = tripDetails.quotes[typeOfservice][legid];
          }
        }
        this.getFlightPlanSelectedData();
        tripServices.legsDetails = tripDetails?.srdata?.Legs?.map(
          (legdata: any) => {
            return {
              ActualLanding: legdata.ActualLanding,
              ActualTakeoff: legdata.ActualTakeoff,
              createdDate: legdata.CreatedDate,
              from: legdata.from,
              to: legdata.to,
              sector: legdata.sector,
              BLKTIME: legdata.BLKTIME,
              legid: legdata.legid,
              Legstage: legdata.Legstage,
              departDate: legdata.depart,
              countdownTime:
              this.tripServices.tripStatus == 'Unapproved'
                  ? this.timerService.timeDiffInSecond(
                    this.timerService.confirmationRequest(
                      tripDetails?.srdata?.Legs[0]
                        .CreatedDate,
                        tripDetails?.srdata?.Legs[0].depart
                    ),
                    this.timerService.getTimeNow()
                  )
                  : this.timerService.timeDiffInSecond(
                    legdata.depart,
                    this.timerService.getTimeNow()
                  ),
            };
          }
        );
        tripDetails.quotes[typeOfservice].forEach((leg, index) => {
          for (const serviceName in leg) {
            if (serviceName == 'permits' || serviceName == 'crew briefing') {
              this.makeServiceLoopArr(
                tripServices,
                leg[serviceName].legid,
                leg[serviceName].sector,
                'sector',
                index
              );
              tripServices.sector.push({
                qoutes:tripDetails.quotes,
                legIndex: index,
                VendorOperationContactNo:
                  leg[serviceName].VendorOperationContactNo != undefined
                    ? leg[serviceName].VendorOperationContactNo
                    : 'Number not found',
                legId: leg[serviceName].legid,
                vendorOpscallingCodes: leg[serviceName].vendorOpscallingCodes,
                subservicedetails:
                  serviceName == 'permits'
                    ? leg[serviceName].subcharges
                    : leg[serviceName].UploadedArray,
                taxes: leg[serviceName].taxes,
                price:
                  leg[serviceName].status == 'inhouse'
                    ? 0
                    : leg[serviceName].price,
                Status: leg[serviceName].status,
                status: this.statusOfService(
                  serviceName,
                  leg[serviceName],
                  tripDetails.srdata.Legs.filter((item) => {
                    return (
                      item.legid.indexOf(String(leg[serviceName].legid)) > -1
                    );
                  })[0].Legstage,
                  tripDetails.status
                ),
                flightPlanningUploaded: tripDetails.srcbparray[
                  leg[serviceName].legid
                ]
                  ? tripDetails.srcbparray[leg[serviceName].legid].length > 0
                    ? true
                    : false
                  : false,
                sector: leg[serviceName].sector,
                legStatus: tripDetails.srdata.Legs.filter((item) => {
                  return (
                    item.legid.indexOf(String(leg[serviceName].legid)) > -1
                  );
                })[0].Legstage,
                tripStatus: tripDetails.status,
                category: this.get_catagory_name(serviceName),
                note:
                  leg[serviceName].status == 'declined'
                    ? 'Select Another Vendor'
                    : '',
                location: leg[serviceName].sector,
                vendorid: leg[serviceName].firebaseID,
                chatFirebaseId: leg[serviceName].firebaseID,
                servicename:
                  serviceName == 'permits'
                    ? 'Overflight Permits'
                    : 'Flight Planning',
                serviceType: 'sector',
                selectFlightPlan:
                  leg.FlightPlanSelected != undefined
                    ? leg.FlightPlanSelected
                    : this.selectFlightPlan,
                requestType:
                  leg[serviceName].status == 'inhouse' ? 'Self' : 'Vendor',
                logo: leg[serviceName].logo,
                departSecond: tripServices.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg[serviceName].legid)) > -1;
                })[0].departDate,
                createSecond: tripDetails.srdata.DateCreated,
                timerCount: this.getServiceDeliveryDateTime(
                  tripDetails.srdata.DateCreated,
                  tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(leg[serviceName].legid)) > -1;
                  })[0].departDate,
                  leg[serviceName].sector,
                  leg[serviceName].sector,
                    tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(leg[serviceName].legid)) > -1;
                  })[0].BLKTIME,
                  tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(leg[serviceName].legid)) > -1;
                  })[0].Legstage,
                  this.tripServices.tripStatus,
                  "timer",
                  "sector",
                ),
                name: leg[serviceName].Vendorname,
                vendorID: leg[serviceName].vendorid,
                cbpprice:
                  serviceName == 'crew briefing'
                    ? leg[serviceName].cbpprice
                    : 0,
                NavigationCost:
                  serviceName == 'permits'
                    ? leg[serviceName].NavigationCost
                    : 0,
                SRID: tripDetails.srid,
                AircraftType: tripDetails.srdata.Regd,
                crewDetails: tripDetails?.assgnedcrewdetails[index]
              });
            } else if (serviceName == 'fuel') {
              leg[serviceName].forEach((element) => {
                this.makeServiceLoopArr(
                  tripServices,
                  element.legid,
                  element.location,
                  'station',
                  index
                );
                tripServices.station.push({
                  legIndex: index,
                  VendorOperationContactNo:
                    leg[serviceName][index] &&
                      leg[serviceName][index].VendorOperationContactNo
                      ? leg[serviceName][index].VendorOperationContactNo
                      : 'Number not found',
                  legId: element.legid,
                  vendorOpscallingCodes:
                    leg[serviceName][index] &&
                    leg[serviceName][index].vendorOpscallingCodes,
                  serviceDetails:
                    tripDetails.status == 'In progress' ||
                      tripDetails.status == 'Completed'
                      ? element.fuelDetailsInprogress
                      : element.fuelsubcharges,
                  taxes: element.taxes,
                  price: element.status == 'inhouse' ? 0 : element.price,
                  Status: element.status,
                  status: this.statusOfService(
                    serviceName,
                    element,
                    tripDetails.srdata.Legs.filter((item) => {
                      return item.legid.indexOf(String(element.legid)) > -1;
                    })[0].Legstage,
                    tripDetails.status
                  ),
                  sector: element.Sector,
                  legStatus: tripDetails.srdata.Legs.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].Legstage,
                  tripStatus: tripDetails.status,
                  location: element.location,
                  vendorid: element.firebaseID,
                  chatFirebaseId: element.firebaseID,
                  category: this.get_catagory_name(serviceName),
                  note:
                    element.status == 'declined' ? 'Select Another Vendor' : '',
                  servicename: 'Fuel',
                  serviceType: 'station',
                  requestType: element.status == 'inhouse' ? 'Self' : 'Vendor',
                  logo: element.logo,
                  departSecond: tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].departDate,
                  createSecond: tripDetails.srdata.DateCreated,
                timerCount: this.getServiceDeliveryDateTime(
                  tripDetails.srdata.DateCreated,
                  tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].departDate,
                  leg[serviceName].sector,
                  leg[serviceName].sector,
                    tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].BLKTIME,
                  tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].Legstage,
                  this.tripServices.tripStatus,
                  "timer",
                  "sector",
                ),
                  fuelDetails: element.status != 'inhouse' ? [
                    trip_status.Inprogress,
                    trip_status.confirmed,
                    trip_status.Upcoming,
                    trip_status.Completed,
                  ].includes(tripDetails.status)
                    ? element.fuelDetails
                    : [] : element.fuelDetails,
                  fuelDetailsInprogress: element.fuelDetailsInprogress,
                  fuelIntoplanAgentDetails: element.fuelIntoplanAgentDetails,
                  name: element.Vendorname,
                  vendorID: element.vendorid,
                  SRID: tripDetails.srid,
                  AcutalFuelBurn: element.fuelquantity,
                });
              });
            } else {
              leg[serviceName].forEach((element) => {
                let onTimeOfService: string;
                let arrivalOrDepature: string;
                if (element.sector.substring(0, 4) == element.location) {
                  onTimeOfService = 'Dep ';
                  arrivalOrDepature = onTimeOfService;
                } else {
                  onTimeOfService = 'Arr ';
                  arrivalOrDepature = onTimeOfService;
                }
                this.makeServiceLoopArr(
                  tripServices,
                  element.legid,
                  element.location,
                  'station',
                  index
                );
                tripServices.station.push({
                  legIndex: index,
                  legId: element.legid,
                  serviceDetails: element.subcharges,
                  taxes: element.taxes,
                  Status: element.status,
                  status: this.statusOfService(
                    serviceName,
                    element,
                    tripDetails.srdata.Legs.filter((item) => {
                      return item.legid.indexOf(String(element.legid)) > -1;
                    })[0].Legstage,
                    tripDetails.status
                  ),
                  price: element.status == 'inhouse' ? 0 : element.price,
                  sector: element.sector,
                  legStatus: tripDetails.srdata.Legs.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].Legstage,
                  tripStatus: tripDetails.status,
                  location: element.location,
                  vendorid: element.firebaseID,
                  chatFirebaseId: element.firebaseID,
                  onTimeOfService,
                  category: this.get_catagory_name(serviceName),
                  servicename:
                    serviceName == 'handling'
                      ? 'Handling'
                      : 'Apt Fees & Permits',
                  VendorOperationContactNo:
                    leg[serviceName][index] &&
                      leg[serviceName][index].VendorOperationContactNo
                      ? leg[serviceName][index].VendorOperationContactNo
                      : 'Number not found',
                  vendorOpscallingCodes:
                    leg[serviceName][index] &&
                    leg[serviceName][index].vendorOpscallingCodes,
                  serviceType: 'station',
                  note:
                    element.status == 'declined' ? 'Select Another Vendor' : '',
                  requestType: element.status == 'inhouse' ? 'Self' : 'Vendor',
                  logo: element.logo,
                  departSecond: tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].departDate,
                  createSecond: tripDetails.srdata.DateCreated,
                timerCount: this.getServiceDeliveryDateTime(
                  tripDetails.srdata.DateCreated,
                  tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].departDate,
                  leg[serviceName].sector,
                  leg[serviceName].sector,
                    tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].BLKTIME,
                  tripServices.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(element.legid)) > -1;
                  })[0].Legstage,
                  this.tripServices.tripStatus,
                  "timer",
                  "sector",
                ),
                  subservicedetails: element.subservicedetails,
                  airlandingandslotpermits:
                    serviceName != 'handling'
                      ? element.airlandingandslotpermits
                      : [],
                  name: element.Vendorname,
                  vendorID: element.vendorid,
                  SRID: tripDetails.srid,
                  handlerdetails: element.handlerDetails,
                  ArrDeptStatus:
                    element.ArrDeptStatus == 'inprogress' ? true : false,
                  fuelDetails:
                    tripDetails.status == 'In progress'
                      ? tripServices.station[0].fuelDetails
                      : [],
                  crewDetails:
                    tripDetails.assgnedcrewdetails &&
                      tripDetails.assgnedcrewdetails[index][element.legid].list &&
                      tripDetails.assgnedcrewdetails[index][element.legid].list
                        .length > 0
                      ? tripDetails.assgnedcrewdetails[index][
                        element.legid
                      ].list.map((crewdata: any) => {
                        return {
                          crewname: crewdata.ClientCrewName,
                          crewid: crewdata.ClientCrewID,
                        };
                      })
                      : [],
                  previousLegId: this.prevLegandSectorForSelfService(
                    element.status,
                    Number(element.legid),
                    typeOfservice,
                    serviceName,
                    tripDetails,
                    element.location,
                    element.sector,
                    'led_id',
                    index
                  ),
                  previousSector: this.prevLegandSectorForSelfService(
                    element.status,
                    Number(element.legid),
                    typeOfservice,
                    serviceName,
                    tripDetails,
                    element.location,
                    element.sector,
                    'sector',
                    index
                  ),
                  previousLegStatus: this.prevLegandSectorForSelfService(
                    element.status,
                    Number(element.legid),
                    typeOfservice,
                    serviceName,
                    tripDetails,
                    element.location,
                    element.sector,
                    'status',
                    index
                  ),
                  DepartureAttachments : element.DepartureAttachments,
                  ArrivalAttachments : element.ArrivalAttachments,
                  arrivalOrDepature,
                });
              });
            }
          }
        });
      }
    }
    return tripServices;
  }
  public prevLegandSectorForSelfService(
    status: string,
    legid: number,
    typeOfService: string,
    serviceName: string,
    tripDetails: any,
    station: string,
    sector: string,
    type: string,
    index: number
  ): any {
    const legIndex = tripDetails.srdata.Legs.map((e) => {
      return e.legid;
    }).indexOf(String(legid));
    if (
      index > 0 &&
      station == sector.substring(0, 4) &&
      tripDetails.srdata.Legs.length > 1 &&
      (status == 'inhouse' || status == 'confirmed')
    ) {
      const prevLeg = tripDetails.srdata.Legs.find(
        (item) => item.legid < legid
      );
      if (type == 'led_id') {
        return prevLeg != undefined ? prevLeg.legid : legid - 1;
      } else if (type == 'sector') {
        return tripDetails.quotes[typeOfService][index - 1][serviceName][0]
          .sector;
      } else if (type == 'status') {
        const legStage = prevLeg != undefined ? prevLeg.Legstage : '';
        if (
          legStage == 'Inprogressstarting' ||
          legStage == 'Inprogressfinishing' ||
          legStage == 'Inprogressfinished'
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return null;
    }
  }
  public statusOfService(
    serviceName: string,
    serviceDetails: any,
    legStatus: string,
    tripStatus: string
  ): string {
    let status: string;
    if (serviceDetails.status != 'declined') {
      switch (serviceName) {
        case 'permits':
          status =
            tripStatus == 'Unapproved'
              ? serviceDetails.status == 'confirmed'
                ? 'accepted'
                : 'pending'
              : serviceDetails.subcharges &&
                serviceDetails.status != 'pending' &&
                serviceDetails.status != 'Closed'
                ? serviceDetails.subcharges.filter((item) => {
                  return item.PermitNumber == null || item.PermitNumber == '';
                }).length <= 0
                  ? 'confirmed'
                  : 'pending'
                : serviceDetails.status;
          if (tripStatus == 'Completed' || serviceDetails.status == 'Closed') {
            status =
              serviceDetails.subcharges.filter((item) => {
                return item.PermitNumber != null || item.PermitNumber != '';
              }).length > 0
                ? 'Done'
                : 'pending';
          }
          break;
        case 'crew briefing':
          status =
            tripStatus == 'Unapproved'
              ? serviceDetails.status == 'confirmed'
                ? 'accepted'
                : 'pending'
              : serviceDetails.UploadedArray
                ? serviceDetails.UploadedArray.length > 0 &&
                  tripStatus != 'Completed'
                  ? 'confirmed'
                  : 'pending'
                : serviceDetails.status;
          if (tripStatus == 'Completed' || serviceDetails.status == 'Closed') {
            status =
              serviceDetails.UploadedArray.length > 0 ? 'Done' : 'pending';
          }
          break;
        case 'fuel':
          if (tripStatus == 'Unapproved') {
            status =
              serviceDetails.status == 'confirmed' ? 'accepted' : 'pending';
          } else if (tripStatus == 'Upcoming') {
            status =
              serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
          } else if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          } else if (tripStatus == 'Confirmed') {
            status =
              serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
          } else if (legStatus == 'Confirmed') {
            status =
              serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
          } else if (
            legStatus == 'Inprogressstarting' ||
            legStatus == 'Inprogressfinishing' ||
            legStatus == 'Inprogressfinished'
          ) {
            status =
              serviceDetails.fuelDetailsInprogress.length > 0
                ? 'confirmed'
                : 'pending';
          } else if (legStatus == 'Closed') {
            status =
              serviceDetails.fuelDetailsInprogress.length > 0
                ? 'Done'
                : 'pending';
          }
          break;
        case 'handling':
          if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          } else if (tripStatus == 'Unapproved') {
            status =
              serviceDetails.status == 'confirmed' ? 'accepted' : 'pending';
          } else if (tripStatus == 'Upcoming') {
            if (serviceDetails.subcharges.Arrival.length) {
              status =
                serviceDetails.subcharges.Arrival.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subcharges.Departure.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            }
          } else if (tripStatus == 'Confirmed') {
            status =
              serviceDetails.UpcomingActivityStatus == 'true'
                ? 'confirmed'
                : 'pending';
          } else if (
            legStatus == 'Inprogressstarting' ||
            legStatus == 'Inprogressfinishing' ||
            legStatus == 'Inprogressfinished'
          ) {
            status =
              serviceDetails.InprogressActivity == 'false'
                ? 'pending'
                : 'confirmed';
          } else if (legStatus == 'Closed') {
            status =
              serviceDetails.InprogressActivity == 'false' ? 'pending' : 'Done';
          } else {
            if (serviceDetails.subservicedetails.Arrival.length) {
              status =
                serviceDetails.subservicedetails.Arrival.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subservicedetails.Departure.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            }
          }
          break;
        case 'airpermissions':
          if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          }
          if (tripStatus == 'Unapproved') {
            status =
              serviceDetails.status == 'confirmed' ? 'accepted' : 'pending';
          } else if (tripStatus == 'Upcoming') {
            if (serviceDetails.subcharges.Arrival.length) {
              status =
                serviceDetails.subcharges.Arrival.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subcharges.Departure.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            }
          } else if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          } else if (tripStatus == 'Confirmed') {
            status =
              serviceDetails.UpcomingActivityStatus == 'true'
                ? 'confirmed'
                : 'pending';
          } else if (
            legStatus == 'Inprogressstarting' ||
            legStatus == 'Inprogressfinishing' ||
            legStatus == 'Inprogressfinished'
          ) {
            status =
              serviceDetails.InprogressActivity == 'false'
                ? 'pending'
                : 'confirmed';
          } else if (legStatus == 'Closed') {
            status =
              serviceDetails.InprogressActivity == 'false' ? 'pending' : 'Done';
          } else {
            if (serviceDetails.subservicedetails.Arrival.length) {
              status =
                serviceDetails.subservicedetails.Arrival.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subservicedetails.Departure.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length > 0
                  ? 'confirmed'
                  : 'pending';
            }
          }
          break;
      }
    } else {
      status = 'declined';
    }
    return status;
  }
  public statusOfVendorService(
    serviceName: string,
    serviceDetails: any,
    legStatus: string,
    tripStatus: string
  ): string {
    let status: string;
    if (serviceDetails.Status != 'declined') {
      switch (serviceName) {
        case 'permits':
          status =
            tripStatus != 'Processing' && tripStatus != 'Declined'
              ? serviceDetails.subcharges.filter((item) => {
                return item.PermitNumber == null || item.PermitNumber == '';
              }).length <= 0
                ? 'confirmed'
                : 'pending'
              : serviceDetails.Status;
          if (tripStatus == 'Closed') {
            status =
              serviceDetails.subcharges.filter((item) => {
                return item.PermitNumber == null || item.PermitNumber == '';
              }).length <= 0
                ? 'Done'
                : 'pending';
          }
          break;
        case 'crew briefing':
          status = serviceDetails.UploadedArray
            ? serviceDetails.UploadedArray.length > 0
              ? 'confirmed'
              : 'pending'
            : serviceDetails.Status;
          if (tripStatus == 'Closed') {
            status = serviceDetails.UploadedArray
              ? serviceDetails.UploadedArray.length > 0
                ? 'Done'
                : 'pending'
              : serviceDetails.Status;
          }
          break;
        case 'fuel':
          if (tripStatus == 'Unapproved') {
            status =
              serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
          } else if (tripStatus == 'Upcoming') {
            status =
              serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
          } else if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          } else if (tripStatus == 'Confirmed') {
            status =
              serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
          } else if (tripStatus == 'Active' || tripStatus == 'Closed') {
            if (
              legStatus == 'Inprogressstarting' ||
              legStatus == 'Inprogressfinishing' ||
              legStatus == 'Inprogressfinished'
            ) {
              status =
                serviceDetails.fuelsubcharges.length > 0
                  ? 'confirmed'
                  : 'pending';
            } else if (tripStatus == 'Closed') {
              if (legStatus == 'Closed') {
                status =
                  serviceDetails.fuelsubcharges.length > 0 ? 'Done' : 'pending';
              }
            } else {
              status =
                serviceDetails.fuelDetails.length > 0 ? 'confirmed' : 'pending';
            }
          }
          break;
        case 'handling':
          if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          } else if (tripStatus == 'Unapproved') {
            if (serviceDetails.subcharges.Arrival.length) {
              status =
                serviceDetails.subcharges.Arrival.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subcharges.Departure.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            }
          } else if (tripStatus == 'Upcoming') {
            if (serviceDetails.subcharges.Arrival.length) {
              status =
                serviceDetails.subcharges.Arrival.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subcharges.Departure.filter((item) => {
                  return item.Doneornot.indexOf('Done') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            }
          } else if (tripStatus == 'Confirmed') {
            status =
              serviceDetails.UpcomingActivityStatus == 'true'
                ? 'confirmed'
                : 'pending';
          } else if (tripStatus == 'Active' || tripStatus == 'Closed') {
            if (
              legStatus == 'Inprogressstarting' ||
              legStatus == 'Inprogressfinishing' ||
              legStatus == 'Inprogressfinished'
            ) {
              status =
                serviceDetails.InprogressActivityStatus == 'false'
                  ? 'pending'
                  : 'confirmed';
            } else if (tripStatus == 'Closed') {
              if (legStatus == 'Closed') {
                status =
                  serviceDetails.InprogressActivityStatus == 'false'
                    ? 'pending'
                    : 'Done';
              }
            } else {
              if (serviceDetails.subcharges.Arrival.length) {
                status =
                  serviceDetails.subcharges.Arrival.filter((item) => {
                    return item.Doneornot.indexOf('Done') > -1;
                  }).length > 0
                    ? 'confirmed'
                    : 'pending';
              } else {
                status =
                  serviceDetails.subcharges.Departure.filter((item) => {
                    return item.Doneornot.indexOf('Done') > -1;
                  }).length > 0
                    ? 'confirmed'
                    : 'pending';
              }
            }
          }
          break;
        case 'airpermissions':
          if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          }
          if (tripStatus == 'Unapproved') {
            if (serviceDetails.subcharges.Arrival.length) {
              status =
                serviceDetails.subservicedetails.Arrival.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subservicedetails.Departure.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            }
          } else if (tripStatus == 'Upcoming') {
            if (serviceDetails.subcharges.Arrival.length) {
              status =
                serviceDetails.subservicedetails.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            } else {
              status =
                serviceDetails.subservicedetails.Departure.filter((item) => {
                  return item.Paidornot.indexOf('Paid') > -1;
                }).length < 0
                  ? 'confirmed'
                  : 'pending';
            }
          } else if (tripStatus == 'Processing') {
            status = serviceDetails.Status;
          } else if (tripStatus == 'Confirmed') {
            status =
              serviceDetails.UpcomingActivityStatus == 'true'
                ? 'confirmed'
                : 'pending';
          } else if (tripStatus == 'Active' || tripStatus == 'Closed') {
            if (
              legStatus == 'Inprogressstarting' ||
              legStatus == 'Inprogressfinishing' ||
              legStatus == 'Inprogressfinished'
            ) {
              status =
                serviceDetails.InprogressActivityStatus == 'false'
                  ? 'pending'
                  : 'confirmed';
            } else if (tripStatus == 'Closed') {
              if (legStatus == 'Closed') {
                status =
                  serviceDetails.InprogressActivityStatus == 'false'
                    ? 'pending'
                    : 'Done';
              }
            } else {
              if (serviceDetails.subcharges.Arrival.length) {
                status =
                  serviceDetails.subcharges.Arrival.filter((item) => {
                    return item.Paidornot.indexOf('Paid') > -1;
                  }).length > 0
                    ? 'confirmed'
                    : 'pending';
              } else {
                status =
                  serviceDetails.subcharges.Departure.filter((item) => {
                    return item.Paidornot.indexOf('Paid') > -1;
                  }).length > 0
                    ? 'confirmed'
                    : 'pending';
              }
            }
          }
          break;
      }
    } else {
      status = 'declined';
    }
    return status;
  }
  public makeServiceLoopArr(
    loop: Service,
    legId,
    location,
    typeOfService: string,
    legIndex
  ) {
    if (typeOfService == 'sector') {
      if (!loop.loopList.sector[legIndex]) {
        loop.loopList.sector[legIndex] = [];
        if (loop.loopList.sector[legIndex].indexOf(location) == -1) {
          loop.loopList.sector[legIndex].push(location);
        }
      } else {
        if (loop.loopList.sector[legIndex].indexOf(location) == -1) {
          loop.loopList.sector[legIndex].push(location);
        }
      }
    } else {
      if (!loop.loopList.station[legIndex]) {
        loop.loopList.station[legIndex] = [];
        if (loop.loopList.station[legIndex].indexOf(location) == -1) {
          loop.loopList.station[legIndex].push(location);
        }
      } else {
        if (loop.loopList.station[legIndex].indexOf(location) == -1) {
          loop.loopList.station[legIndex].push(location);
        }
      }
    }
  }
  // select diiferenet vendor if the request id decliend
  /**
   *
   * @param service the complete declined service details for which the user want to selct different vendor.
   */
  public getOthervendor(service: TripServices) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody =
        'sector=' +
        service.sector +
        '&station=' +
        service.location +
        '&service=' +
        service.servicename +
        '&srid=' +
        service.SRID +
        '&SRSecID=' +
        service.legId;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          activityUriConfig.GetReSelectVendor,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          this.commonService.dismissLoader();
          res.ChooseVendor.forEach((element) => {
            element.selected = false;
          });
          resolve(res.ChooseVendor);
        })
        .catch((error) => {
          this.commonService.dismissLoader();
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.getOthervendor(service);
            } else {
              reject();
            }
          });
        });
    });
  }
  public selectOtherVendor(data: any) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody = 'data=' + JSON.stringify(data);
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          activityUriConfig.ReSelectVendor,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          this.commonService.dismissLoader();
          resolve(res.ResponseStatus);
        })
        .catch((error) => {
          this.commonService.dismissLoader();
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.selectOtherVendor(data);
            } else {
              reject();
            }
          });
        });
    });
  }
  public get_catagory_name(name: string): string {
    let catagory: string;
    switch (name) {
      case 'handling':
        catagory = 'Handling';
        break;
      case 'airpermissions':
        catagory = 'Airport Charges';
        break;
      case 'crew briefing':
        catagory = 'Flight Planning';
        break;
      case 'permits':
        catagory = 'Overflight';
        break;
      case 'fuel':
        catagory = 'Fuel';
        break;
    }
    return catagory;
  }
  public getServiceDeliveryDateTime(
    createDate: string,
    DepartDate: string,
    sector: string,
    station: string,
    flyingtime: string,
    legStatus: string,
    tripStatus: string,
    returnType: string,
    typeOFservice: string
  ): any {
    if (tripStatus == 'Unapproved' || tripStatus == 'Processing') {
      if (tripStatus == 'Unapproved') {
        if (returnType == 'progressbar') {
          return this.checkAriivalDepartureStation(
            sector,
            station,
            DepartDate,
            flyingtime,
            typeOFservice
          );
        } else {
          return this.timerService.timeDiffInSecond(
            this.checkAriivalDepartureStation(
              sector,
              station,
              DepartDate,
              flyingtime,
              typeOFservice
            ),
            this.timerService.getTimeNow()
          );
        }
      } else {
        if (returnType == 'progressbar') {
          return this.timerService.confirmationRequest(createDate, DepartDate);
        } else {
          return this.timerService.timeDiffInSecond(
            this.timerService.confirmationRequest(createDate, DepartDate),
            this.timerService.getTimeNow()
          );
        }
      }
    } else {
      if (returnType == 'progressbar') {
        return this.checkAriivalDepartureStation(
          sector,
          station,
          DepartDate,
          flyingtime,
          typeOFservice
        );
      } else {
        return this.timerService.timeDiffInSecond(
          this.checkAriivalDepartureStation(
            sector,
            station,
            DepartDate,
            flyingtime,
            typeOFservice
          ),
          this.timerService.getTimeNow()
        );
      }
    }
  }
  public checkAriivalDepartureStation(
    sector: string,
    station: string,
    departDate: string,
    flyingTime: string,
    typeOFservice: string
  ) {
    if (typeOFservice != 'sector') {
      if (sector.substring(0, 4) == station) {
        return departDate;
      } else {
        return this.timerService.addMinutes(
          this.timerService.getTimeInMin(flyingTime),
          departDate
        );
      }
    } else {
      return departDate;
    }
  }
}
