export const DateFormate = {
  displayDateFormate: 'MMM DD, YYYY',
  displayDateMinFormate: 'MMM DD, YYYY, hh:mm',
  dispalyMinDate: 'MMM DD, YYYY, HH:mm',
  displayZuluDate: 'MMM DD, YYYY HH:mm Z',
  displayHours: 'HH:mm',
  dateFormate: 'MMM dd, yyyy',
  dateMin: 'MMM dd, yyyy HH:mm',
  formateDate: 'MMM dd, yyyy',
  updatedFormat: 'MMM dd, yyyy HH:mm'
};
export const MessageConfig = {
  NoTaxesApplicable: 'No taxes are applicable.',
  NoSurchargeApplicable: 'No surcharges are applicable.',
  NoFeesApplicable: 'No fees are applicable.',
  NoOtherServiceApplicable: 'No other services are applicable.',
  NoAirportPermissionApplicable: 'No Airport Permissions are applicable...',
  pinRequired: 'Pin is required',
  fuelType: 'Fuel type is required',
  InsufficientBalance:
    'Insufficient balance. Required amount to activate trip is <b>funds </b> USD',
  ValidRange: 'Maximum range should be greater than minimum range',
  ParkingAlert: 'Please enter the weight ranges or price type.',
  downLoading: 'Downloading...',
  fetchFuelDetails: 'fetching fuel details...',
  loadingMoreData: 'Loading more data...',
  ApiErrorHeader: 'Oops!',
  APISuccessMessage: 'Success',
  ApiErrorMessage: 'Error! Please try again',
  CancelButton: 'Cancel',
  TryAgain: 'Try Again',
  NoDataAvailable: 'No data available...',
  NoTaxes: 'No taxes available...',
  Amount: 'Amount required',
  AmountValid: 'Enter valid amount required',
  taxAmount: 'Tax Amount is required',
  taxValid: 'Enter valid tax required',
  TaxName: "Tax Name required.",
  TaxRate: "Tax Rate required.",
  Attachdocs: 'Attach Document',
  tripKitName: "Attach Trip Kit",
  fileUploadSize: '.doc, .docx, .pdf, .png, .jpeg. Max file size 7Mb.',
  Alert: 'Alert!',
  Error: 'Error!',
  NotValidRoute: 'Flight Plan is not validated.',
  FinalLize: 'Are you sure you want to Select Flight Plan?',
  FileSizeforAttachment:
    'File size exceeded! Please upload a file smaller than 7MB',
  InvalidFilePath: 'file path',
  NoImageAvailable: 'assets/imgs/no_image_available.svg',
  Companyname: 'Company name required.',
  Email: 'Email address required.',
  ValidEmail: 'You have entered an invalid email address.',
  // EmailLength:'Sorry, your Email address must be alphanumeric between 6 and 30 characters long.',
  ContactNumber: 'Valid contact no required.',
  contactNumber: 'Contact Number is required',
  passwordReuired: 'Please provide a password.',
  PasswordLength: 'Password is too short (minimum 8 characters).',
  PasswordCriteria: 'Password should not contain space.',
  PasswordMismatch: 'Password and confirm password should match.',
  WrongLoginAlert:
    'Username or Password may be incorrect. Please enter correct username and password.',
  LoginErrorTitle: 'LogIn Alert',
  WrongSignupAlert: 'The email address is already in use by another account.',
  emailverify:
    'Please verify your email address by clicking on the link sent to your registered email address.',
  emailVerification: 'User registration successful. Verification email sent.',
  PasswordDetected: 'No user found with this e-mail address.',
  PasswordReset: 'Reset link emailed',
  AlreadyAccount: 'Already have an account?',
  Signin: 'Sign In',
  wifiOfIcon :'<ion-icon name="cloud-offline-outline"></ion-icon>',
  wifiIcon :'<ion-icon name="cloud-outline"></ion-icon>',
  onlineAlert:'  You are online!',
  offlineAlert:'  You are currently offline!',
  wrongAlert:'Oops! Something went wrong. Please try again.',
  ValidCharcters: 'Please fill valid characters.',
  PriceRequired: 'Price is required.',
  BrandName: 'Brand name required.',
  Currancy: 'Currancy required',
  FirstName: 'Name required.',
  Address: 'Address required',
  TailNo: 'Tail No. is required.',
  MTOWWeight: 'MTOW Weight is required.',
  CompanyNo: 'Company number required.',
  BankName: 'Please fill in the bank name',
  BranchName: 'Please fill in the branch name',
  TaxId: 'Tax identification number required.',
  ACNumber: 'Please fill bank account number',
  RoutingCode: "Please fill your bank's routing code",
  pincode: 'Please fill a valid pincode.',
  countryname: 'Please select a country.',
  phoneNumber: 'Please enter valid phone number.',
  cancel: 'cancel',
  ok: 'Ok',
  OkBtn: 'Ok',
  ValidAcNo: 'Current account number must be atleast 4 digit long and 15 max',
  DeleteEmailID: 'Username doesn`t exist',
  UploadImage: 'Image size exceeded! Please upload an image smaller than 7MB.',
  ConfirmDelete:
    'Once you confirm all of your account data will be permanently deleted.',
  LogOut: 'You want to logout from all devices?',
  Confirmation: 'Confirm',
  PassportDateExpired: 'Passport validity expired',
  MobileNumberRequired: 'Mobile number required.',
  CountryCodeRequired: 'Coutry code required.',
  PinConfirmation: 'You want to regenerate the Pin?',
  RestrictedPin:
    'You are restricted to generate a new pin for the next 15 minutes, Please check your mailbox for a recent pin.',
  ContactPluginIssue: 'Something went wrong. Please try again.',
  GetPin: 'Secure Pin has been sent to your register Email Id.',
  verifyPin:
    "The pin doesn't match for multiple times.Please try again after 10 minutes.",
  verifyPinError:
    'Incorrect Pin. Access will be invalidated after 3 incorrect attempts.',
  DeclinedFlightPlan:
    'Cancellation of flight plan, will be noticed to the respected AFTN Address.',
  AcceptButtonText: 'Ok',
  CancelFlight: 'Please wait while we cancel the flight plan...',
  DelayFlight: 'Please wait while we delay the flight plan...',
  ChangeFlight: 'Please wait while we change the flight plan...',
  filedflightPlanDownload: 'Please wait while we donwload the plan...',
  PlanName: 'Into Plane Agent name required',
  Quantity: 'Fuel quantity required',
  FdnNumber: 'FDN number is required',
  DeleteCard: 'You want to delete this FDN?',
  DeleteHandling: 'You want to delete this Attachment?',
  DeleteAPT:'You want to delete this Attachment?',
  DriverName: 'Driver name required',
  DriverNumber: 'Driver number required',
  NoOfCar: 'Number of car required',
  NoOfTrip: 'Number of trip required',
  Signature: 'Please provide your sign or name.',
  DeclineAllService: 'You want to decline all the above Services?',
  AcceptAllService: 'You want to accept all the above Services?',
  PassengersName: 'Please enter passenger name ',
  PassengerGender: 'Please enter passenger gender',
  PassengersContactNo: 'Please enter passenger contact no ',
  PassengersEmailId: 'Please enter passenger email',
  ValidPassengersName:
    'Please enter valid passenger name of maximum 20 characters',
  PassengersDOB: 'Please enter passenger DOB ',
  PassengersNationality: 'Please enter passenger nationality',
  PassengersPassport: 'Please enter passenger passport no',
  PassengersPassportValidity:
    'Please enter the passenger passport validity date',
  PassengersPassportExpiy: 'Please enter the passenger passport expiry date',
  PassengersStatus: 'Required field',
  PassengersNameMinLength: 'Name is too short (minimum 4 characters).',
  CrewName: 'Please enter crew name ',
  CrewGender: 'Please enter crew gender ',
  CrewContactNo: 'Please enter crew contact no ',
  CrewCountrycode: 'Please Select Dial Code ',
  CrewEmailId: 'Please enter crew email',
  ValidCrewName: 'Please enter valid crew name of maximum 20 characters',
  CrewDOB: 'Please enter crew DOB ',
  CrewNationality: 'Please enter crew nationality',
  CrewPassport: 'Please enter crew passport no',
  CrewPassportValidity: 'Please enter the crew passport validity date',
  CrewPassportExpiy: 'Please enter the crew passport expiry date',
  CrewDuties: 'Please enter the crew duties',
  CrewStatus: 'Required field',
  CrewNameMinLength: 'Name is too short (minimum 4 characters).',
  EnterDetails: 'Please enter all the details',
  validEmail: 'Please enter a valid email',
  validpaxName: 'Please enter a valid passenger name',
  validcrewName: 'Please enter a valid crew name',
  deleteCrew: 'You want to delete this Crew?',
  deletePax: 'You want to delete this Passenger?',
  SelfServiceTce: 'Self Service',
  UploadFlightPlan: 'Please wait while we submit the plan',
  EntryPoint: 'Entry Point',
  ExitPoint: 'Exit Point',
  Distance: 'Distance',
  Time: 'Time',
  NavFee: 'Navigation Fees',
  Permitpost: 'Permit Validity Post required.',
  Leadtime: 'Lead Time required.',
  Overflightprice: 'Overflight Price required.',
  Revisionprice: 'Revision Price required.',
  Shortprice: 'Short Notice Permit Price required.',
  Slotprice: 'Slot Price required.',
  Landprice: 'Landing Price required.',
  Flightprice: 'Flight Planning Price required.',
  Etopsprice: 'ETOPS Price required.',
  Nonetopsprice: 'Non ETOPS Price required.',
  Permitprior: 'Permit Validity Prior required.',
  CurrencyConversionAlert: 'Currency fluctuation, please reconfirm your price.',
  AttachTripKit: 'Please wait while we File the flight plan',
  pincodeno: ' Please enter numbers and alphabets only.',
  RemoveSurchargeMessage: 'You want to remove this Surcharge?',
  Currency: 'Currency required',
  operationalFrom: 'Airport operational hours From is required.',
  operationalTo: 'Airport operational hours to is required.',
  ServicesRquired: 'Please select services you provide',
  DeleteRateCard: "You want to delete this Rate Card?",
  ciqstartend: "Required field",
  pushNotificationTitle: "New Notification",
  arrangeService: 'Are you sure all services Arranged?',
  selectService:'Have you selected all Services?',
  doneService:'Are you sure all services Done?',
  FinalizeFlightPlan:'Please add Crew before Finalize Flight Plan.',
  defaultCurrency: {
    currencyId: 'USD',
    name: 'USD , United States Dollar',
  },
  Unauthorized:
    'we apologize for the inconvenience , but you are not authorizes to visit this page.If you believe this is an error, please contact us.',
  ConfirmFeedback:
    'Thank you for your valuable feedback. It helps to improve our services',
  DisconnectMessage: 'You want to disconnect ?',
  HandlingOtherService: {
    privatepassengervip: 'VIP Lounge',
    passengerciq: 'Passenger CIQ',
    paxNormal: 'Pax Transport',
    PaxTransport: 'Pax Transpor',
    gendec: 'GENDEC',
    Passenger: 'Passenger',
    clearance: 'clearance',
  },
  PeakHourName: "Peak Hour Name required.",
  addingAirportRateCard: 'Adding rate cards...',
  fetchingAirportRateCard: 'Getting rate cards...',
  updateAirportRateCard: 'Updating rate cards...',
  DeleteUser: 'Are you sure you want to delete this user?',
  StnReq: 'Station is Requried.',
  AircraftReq: 'Aircraft is required.',
  FlyTo: 'Flying To is required.',
  RegsterType: 'Registered Type is required.',
  Operation: 'Operation Type is required.',
  FltType: 'Flight Type is required.',
  StpType: 'Stop Type is required.',
  Fueling: 'Fueling is required.',
  VendorType: 'Vendor Type is required.',
  FuelerName: 'Fueler Name is required.',
  PapPrice: "Pap Price required.",
  ThroughputFee: "Throughput fee required.",
  DifferentialPrice: "Differential Price required.",
  PlattsPrice: "Platts Price required.",
  HandlerName: "Handler Name is required.",
  HandlerContactNo: 'Please enter passenger contact no.',
  Handleremail: "Please enter Handler email.",
  CiqStartFrom: "From Required field",
  CiqStartEnd: "To Required field",
  TimeRangeFromTo: "Please fill both 'From' and 'To' time fields.",
  FreeAndRampHoursValidation:"Parking Hours must be less than Ramp Hours.",
  RampAndFreeHoursValidation: "Ramp Hours must be greater than Parking Hours.",
  FreeParkHour: "Free Parking Time required",
  RampParkingtime: "Max Time For Ramp Parking required",
  LandingGrace: "After Landing Grace Period required",
  TakeoffGrace: "Before Take-off Grace Period required",
  SampleTaxesCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-local.appspot.com/o/TaxescsvSample%2FTaxesSampleCsv.csv?alt=media&token=441fe26a-d047-4e37-b1b7-9d27be35b080",
  SampleHandlingCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-local.appspot.com/o/GHExel%2FGroundHanling.csv?alt=media&token=d46d0490-6321-4b8f-8d31-78a6c0b5d9f9",
  GroundHandlingCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-7dd2c.appspot.com/o/csv_sample_file%2FGroundHanling.csv?alt=media&token=d706828b-9e5b-40bf-9424-54245a33b54e",
  FuelSampleCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-7dd2c.appspot.com/o/csv_sample_file%2FFuel.csv?alt=media&token=d706828b-9e5b-40bf-9424-54245a33b54e",
  TaxesSampleCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-7dd2c.appspot.com/o/csv_sample_file%2FTaxes.csv?alt=media&token=d706828b-9e5b-40bf-9424-54245a33b54e",
  OvfSampleCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-7dd2c.appspot.com/o/csv_sample_file%2FOverFlightpermit.csv?alt=media&token=d706828b-9e5b-40bf-9424-54245a33b54e",
  LandingSampleCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-7dd2c.appspot.com/o/csv_sample_file%2FLanding.csv?alt=media&token=d706828b-9e5b-40bf-9424-54245a33b54e",
  SlotSampleCsvFile:
    "https://firebasestorage.googleapis.com/v0/b/avplat-7dd2c.appspot.com/o/csv_sample_file%2FSlotPrice.csv?alt=media&token=d706828b-9e5b-40bf-9424-54245a33b54e" ,
    RecentChat: "Delete this Recent Chat?",
  HolidayName: "Holiday Name required.",
  PeakPeriodName: "Peak Period Name required.",
  InAppBrowserURL: "https://avplat.com/terms-of-service/",
  Aircraft: 'Aircraft is required.',
  Flyingto: 'Flying To is required.',
  Regstertype: 'Registered Type is required.',
  Opeartion: ' Operation Type is required.',
  FlightType: 'Flight Type is required.',
  Vendortype: ' Vendor Type is required.',
  specificGravity: 'Specific gravity is required.',
  Specificgravity: 'Specific gravity should be in between 0 and 1',
  FlightPlanNotUpload: "Flight plan is not uploaded yet...",
  SurchargesConfirm: "You want to delete this Surcharge?",
  ResetAllparkingRanges: "You want to delete this Range?",
  RouteValidated: "The Route is now Validated. Please note that we have updated the Route to comply with Rules and Restrictions.",
  selectAircraft: 'Are you sure you want to select this Aircraft?',
  PermitNumber: 'permit number',
  BayNumber: 'bay number',
  PlayStoreUrl:'https://play.google.com/store/apps/details?id=com.avplat.aviation&hl=en_US',
  AppStoreUrl:'https://apps.apple.com/in/app/avplat/id1369917026',
  AppUpdateMessage:'Please update app for better experience',
  PercentageValidation: 'The percentage must be between 0 and 100.',
  tripCancel:'The trip was cancelled',
  delayHoursFlightPlan:48
};
export const Status = {
  Inprogressstarting: 'Inprogressstarting',
  Inprogressfinishing: 'Inprogressfinishing',
  Inprogressfinished: 'Inprogressfinished',
  Confirmed: 'Confirmed',
  Processing: 'Processing',
  Self: 'Self',
  Operator: 'Operator',
  Vendor: 'Vendor',
  Pending: 'Pending',
  Declined: 'Declined',
};
export const PageHeader = {
  NavFees: 'Nav Fees',
  OvfPermits: 'Ovf Permits',
  OverflightPermits: 'Nav Fees & Permits',
  Overflight: 'Overflight Permits',
  Permit: 'Nav Fees & Permits',
  FlightPlanning: 'Flight Planning',
  FileFlightPlan: 'File Flight Plan',
  DelayFlightPlan: 'Delay Flight Plan',
  ChangeFlightPlan: 'Change Flight Plan',
  Fuel: 'Fuel',
  TripKit: 'Trip Kit',
  TripKitAttach: 'Trip Kit Attachment',
  AttachCBP: 'Attach CBP',
  Handling: 'Handling',
  ArrHandling: 'Arr Handling',
  DepHandling: 'Dep Handling',
  ArrDepHandling: 'Arr & Dep Handling',
  AirportCharges: 'Apt Fees & Permits',
  ArrDepAirportCharges: 'Arr & Dep Airport Charges',
  AirportChargesPermission: 'Apt Fees & Permits',
  ArrAirportCharges: 'Arr Apt Fees & Permits',
  ArrAirportChargesPermission: 'Apt Fees & Permits',
  DepAirportCharges: 'Dep Apt Fees & Permits',
  DepAirportChargesPermission: 'Apt Fees & Permits',
  CBPFinaalize: 'CBP Finalize',
  TripKitFinaalize: 'Trip Kit Finalize',
  SelectFlightPlan: 'Finalize Flight Plan',
  SelectFuelOn: 'Select Fuel On',
  SelectHandlingServices: 'Select Handling Services',
  groundhandling: 'Handling',
  airportcharges: 'Apt Fees & Permits',
  crewbriefing: 'Flight Planning',
  Navigation: 'Nav Fees & Permits',
  FileFlightPlanAndGenerateTripKit: 'File And Generate Trip Kit',
};
export const ConstantNames = {
  Frombtn: 'From',
  ToBtn: 'To',
  displayFormat: 'YYYY MMM DD, HH:mm',
  tripDateFormat: 'EEEE MMM d y h:mm',
  AircraftName: 'Aircraft',
  CrewName: 'Crew',
  PaxName: 'Pax',
  Alt1: 'ALTN 1',
  Alt2: 'ALTN 2',
  CruiseAlt: 'FL',
  Cruisespd: 'SPD',
  Fuel: 'Fuel',
  FlightRule: 'Flight Rule',
  FlightType: 'Flight Type',
  OperationType: 'Operation Type',
  ToALTN: 'T/O ALTN',
};
export const SkeltonDisplayLines = [
  { showLine: true, lineNo: 1, lineWidth: '20%' },
  { showLine: true, lineNo: 2, lineWidth: '30%' },
  { showLine: true, lineNo: 3, lineWidth: '60%' },
  { showLine: true, lineNo: 4, lineWidth: '20%' },
];
export enum ApiTypes {
  POST,
  GET,
  PATCH,
  DELETE,
  PUT,
}
export const DefaultCurrencyConfig = {
  Currency: {
    currencyId: 'USD',
    name: 'USD , United States Dollar',
  },
};
export const walletMessages = {
  ValidCharcters: "Please fill valid characters.",
  FirstName: "Name required.",
  Card: "Card Number required",
  Amount: "Amount required",
  AmountMin: "Minimum Amount required",
  Expiry: "Expiry required",
};
export const RefreshByStatusList = [
  { type: 'All', refreshBy: 'tripall' },
  { type: 'Upcoming', refreshBy: 'Confirmed' },
  { type: 'In Progress', refreshBy: 'Active' },
  { type: 'Completed', refreshBy: 'Closed' },
  { type: 'Cancelled', refreshBy: 'Tripcancelled' },
  { type: 'Pending', refreshBy: 'Processing' },
  { type: 'Declined', refreshBy: 'declined' },
];
export const ButtonText = {
  arrange: 'Arrange',
  save: 'Save',
  done: 'Done',
};
export enum PatternForNames {
  NameValidation = '',
  EmailValidation = ""
}
export enum FirebasePlaformKey {
  key = "BYLd6BxUByVKGsqQg6HsxLMfgTH2",
}

export const layerList = [
  { label: 'Political', value: 'none' },
  { label: 'HI', value: 'hi' },
  { label: 'LOW', value: 'lo' },
  { label: 'VFR', value: 'vfr' },
  { label: 'SIGMET', value: 'wx' },
  { label: 'SigWx', value: 'sigwx' },
  { label: 'Winds', value: 'winds' },
  { label: 'FIRs', value: 'firs' },
  { label: 'Airports', value: 'airports' },
]

export const defaultSESpeed = 320;
